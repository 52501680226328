/* Imports happen in Gulp */
var stylesheet = loadCSS( ThemeStyle, document.getElementById("loadcss"));
onloadCSS( stylesheet, function() {

  //Webfonts
  var WebFontConfig = {
    custom: {
      families: [
        'Font Awesome 5 Free',
        'Font Awesome 5 Brands',
        'Crimson Text',
        'Raleway'
      ],

      urls: [LoadFonts]
    }
  };
  WebFont.load(WebFontConfig);


  $('.show-form-and-go-to').on('click', function(e) {
    e.preventDefault();

    $('html,body').animate({
      scrollTop: $('.outer-informatie-aanvraag-form').offset().top
    }, 500);
    $('.informatie-aanvraag-form').collapse();

  });

  $('.informatie-aanvraag-form').on('hide.bs.collapse', function(e) {
    e.preventDefault();

    var $collapse = $(this);

    if($collapse.hasClass('show')){
      $('html,body').animate({
        scrollTop: $collapse.offset().top
      }, 500);
    }
  });

  //Animate to next element
  $('.element').on('click', '.goto-nextblock', function (e) {
      e.preventDefault();
      var nextElement = $(this).closest('.element').next();
      $('html, body').animate({
          scrollTop: $(nextElement).offset().top
      }, 500);
  });

  //Animate to next element
  $('.goto-link-specs').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({
          scrollTop: $('.product-specificaties').offset().top
      }, 500);
  });

  //Animate anchors
  $(document).on('click', 'a[href^="#"]', function (e) {
      e.preventDefault();

      $('html, body').animate({
          scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
  });

  //Animate anchors
  $(document).on('click', '.section-navigation .go-to', function (e) {
      e.preventDefault();

      $('html, body').animate({
          scrollTop: $($(this).data('goto')).offset().top
      }, 500);
  });

  //FAQ
  $('.collapse').on('shown.bs.collapse', function(){
    $(this).parent().find(".fa-plus").removeClass("fa-plus").addClass("fa-minus");
    }).on('hidden.bs.collapse', function(){
    $(this).parent().find(".fa-minus").removeClass("fa-minus").addClass("fa-plus");
  });

  //Lazyload background images
  document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  });

  //Mobile Utilities
  function mobileAdjustments(){
    var sm = window.matchMedia("(max-width: 576px)");
    var md = window.matchMedia("(max-width: 768px)");
    var lg = window.matchMedia("(max-width: 992px)");
    var xl = window.matchMedia("(max-width: 1200px)");
    var xxl = window.matchMedia("(max-width: 1400px)");

    if(xl.matches){
      $('.collapse-mobile').removeClass('show');

      $('.main-image-block').removeClass('mt-4');
      $('.main-image-block').addClass('mt-0');
      $('.main-image-block').prependTo('.add-tocart-product .card-body');
    }else{
      $('.collapse-mobile').addClass('show');

      $('.main-image-block').addClass('mt-4');
      $('.main-image-block').removeClass('mt-0');
      $('.main-image-block').prependTo('.image-column');
    }

    if(lg.matches){

      $('.header .move-cart-icon').removeClass('navigation-item');
      $('.megamenu-navigation .navigation-menu .move-cart-icon').addClass('list-inline-item');
      $('.megamenu-navigation .navigation-menu .move-cart-icon').insertBefore('.header .toggle-mobile');

      $('.move-top-link').addClass('navigation-item');
      $('.move-top-link .topbar-link').addClass('navigation-link');
      $('.move-top-link').appendTo('.megamenu-navigation .navigation-menu');

    }else{

      $('.megamenu-navigation .navigation-menu .move-cart-icon').addClass('navigation-item');
      $('.header .move-cart-icon').removeClass('list-inline-item');
      $('.header .move-cart-icon').appendTo('.header .megamenu-navigation .navigation-menu');

      $('.move-top-link').removeClass('navigation-item');
      $('.move-top-link .topbar-link').removeClass('navigation-link');
      $('.megamenu-navigation .navigation-menu .move-top-link').appendTo('.top-nav');

    }
  }

  $(document).ready(function(){




    var totalcheckboxes = $('#Form_FilterForm .filter-form--options .checkboxset, #Form_FilterForm .filter-form--options .optionset').length + 20;

    $('.filter-form--options-wrap .checkboxset, .filter-form--options-wrap .optionset').each(function(index){
      var collapseButton = $(this).find('label.left');
      var collapseArea = $(this).find('.middleColumn');
      var checkboxes = $(this).find("input");

      collapseArea.css('z-index', totalcheckboxes);
      totalcheckboxes--;

      if (checkboxes.is(':checked')){
        collapseButton.addClass('has-options-checked');
      }

      collapseArea.find('input').click(function(){
        if (checkboxes.is(':checked')){
          collapseButton.addClass('has-options-checked');
        }else{
          collapseButton.removeClass('has-options-checked');
        }
      });

      collapseButton.click(function(){
        collapseArea.collapse('toggle');
        collapseButton.toggleClass('active');
      });
    });

    //Animate to next step checkout
    if($('.message.error').length){
    }else{
      if($('.checkout-content .active-step').length){
        $('html, body').animate({
            scrollTop: $('.active-step').offset().top
        }, 500);

      }
    }

    $('.winkelwagen-modal').on('shown.bs.modal', function () {
      Cookies.remove('show-cart');
    });

    if(Cookies.get('show-cart') == true){
      $('.winkelwagen-modal').modal('show');
    }

    $('.show-cart-link').on('click', function (e) {
        e.preventDefault();
        Cookies.set('show-cart', 1);
        window.location.href = $(this).attr("href");
    });

    mobileAdjustments();
    $(window).on('resize', function(){
      mobileAdjustments();
    });

    $('[data-toggle="tooltip"]').tooltip({container: 'body'});

    if($('.show-modal').length != false){
      $('.show-modal').closest('.modal').modal('show');
    }

    if($('.form-failed').length != false){
      $('.form-failed').closest('.modal').modal('show');
    }

    $(".animsition").animsition({
        inClass: 'fade-in',
        outClass: 'fade-out',
        inDuration: 1000,
        outDuration: 300,
        linkElement: '.animsition-link',
        loading: true,
        loadingParentElement: 'body',
        loadingClass: 'animsition-loading',
        timeout: false,
        timeoutCountdown: 5000,
        onLoadEvent: true,
        browser: [ 'animation-duration', '-webkit-animation-duration'],
        overlay : false,
        overlayClass : 'animsition-overlay-slide',
        overlayParentElement : 'body',
        transition: function(url){ window.location.href = url; }
    });


  });

});
